import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = lng === "fa" ? "rtl" : "ltr";
    if (lng === "fa") {
      document.body.classList.add("style_rtl");
    } else {
      document.body.classList.remove("style_rtl");
    }
  };
  return (
    <div>
      <button className="btn btn-custom btn-md" onClick={() => changeLanguage(i18n.language === "fa" ? "en" : "fa")}>EN/FA</button>
    </div>
  );
};

export default LanguageSwitcher;
