import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";

export const Navigation = (props) => {
  console.log(props.data);
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img src="img/logo.png" alt="logo" height={"100%"} />
          </a>
        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {props.data
              ? props.data.map((n, i) => (
                  <li key={`${Object.keys(n)[0]}-${i}`}>
                    <a href={`#${Object.keys(n)[0]}`} className="">
                      {n[Object.keys(n)[0]]}
                    </a>
                  </li>
                ))
              : "Loading..."}
            <li className="lang_switcher">
              <LanguageSwitcher />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
