import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    // TODO: replace below with your own Service ID, Template ID and Public Key from your EmailJS account

    emailjs
      .sendForm(
        "maryamact.@yahoo.com",
        // "YOUR_TEMPLATE_ID",
        e.target,
        "maryamact.@yahoo.com"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8 hidden">
            <div className="row">
              <div className="section-title">
                <h2>{props?.data?.title}</h2>
                <p>{props?.data?.paragraph}</p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder={props?.data?.form.name}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder={props?.data?.form.email}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder={props?.data?.form.message}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  {props?.data?.form.send}
                </button>
              </form>
            </div>
          </div>
          <div className=" contact-info">
              <h3>{props?.data?.info.title}</h3>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-map-marker"></i>
                  {props?.data?.form.address}
                </span>
                {props?.data?.info.address}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i>
                  {props?.data?.form.phone}
                </span>
                <a
                  href={`tel:${props?.data?.info.phone1}`}
                  title={props?.data?.info.phone1}
                >
                  {props?.data?.info.phone1}
                </a>
                <a
                  href={`tel:${props?.data?.info.phone2}`}
                  title={props?.data?.info.phone2}
                >
                  {props?.data?.info.phone2}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i>
                  {props?.data?.form.email}
                </span>
                <a
                  href={`mailto:${props?.data?.info.email}`}
                  title={props?.data?.info.email}
                >
                  {props?.data?.info.email}
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props?.data?.info.facebook}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props?.data?.info.twitter}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props?.data?.info.youtube}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        {/* <div className="container text-center">
          
        </div> */}
      </div>
    </div>
  );
};
