import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Products } from "./components/products";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import enData from "./locales/en.json";
import faData from "./locales/fa.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});
i18n.init({
  resources: {
    en: { translation: enData },
    fa: { translation: faData },
  },
  lng: "en", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});
const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(i18n.language === "fa" ? faData : enData);
  }, []);
  i18n.on("languageChanged", (lng) => {
    setLandingPageData(lng === "fa" ? faData : enData);
  });
  return (
    <I18nextProvider i18n={i18n}>
      <div>
        <Navigation data={landingPageData.Navigation} />
        <Header data={landingPageData.Header} />
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        <Products data={landingPageData.Products} />
        {/* <Gallery data={landingPageData.Gallery} /> */}
        {/* <Testimonials data={landingPageData.Testimonials} /> */}
        {/* <Team data={landingPageData.Team} /> */}
        <Contact data={landingPageData.Contact} />
      </div>
    </I18nextProvider>
  );
};

export default App;
